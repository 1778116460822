import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import './Player.css';

const socket = io('https://triviagame-woe6.onrender.com');

const Player = () => {
    const [roomCode, setRoomCode] = useState('');
    const [playerName, setPlayerName] = useState('');
    const [gameStarted, setGameStarted] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [answered, setAnswered] = useState(false);
    const [timer, setTimer] = useState(10);
    const [showScoreboard, setShowScoreboard] = useState(false);
    const [scoreboard, setScoreboard] = useState({});
    const [result, setResult] = useState(null);

    useEffect(() => {
        socket.on('newQuestion', (data) => {
            setGameStarted(true);
            setCurrentQuestion(data.question);
            setAnswered(false);
            setShowScoreboard(false);
            setTimer(data.timeLeft);
        });

        socket.on('updateTimer', (timeLeft) => setTimer(timeLeft));

        socket.on('playerJoined', (players) => {
            const playerList = Object.values(players).map(player => player.name.toUpperCase());
            setParticipants(playerList);
        });

        socket.on('updateScoreboard', (data) => {
            setScoreboard(data.players);
            setShowScoreboard(data.showResults);
            if (data.players[socket.id]) {
                setResult(data.players[socket.id].answeredCorrectly);
            }
        });

        return () => {
            socket.off('newQuestion');
            socket.off('updateTimer');
            socket.off('playerJoined');
            socket.off('updateScoreboard');
        };
    }, [playerName]);

    const joinGame = () => socket.emit('joinGame', { roomCode, playerName });

    const submitAnswer = (option) => {
        if (!answered) {
            setAnswered(true);
            const isCorrect = option === currentQuestion.correctAnswer;
            const bonusPoints = isCorrect ? timer * 10 : 0; // Bonus points based on remaining time
            socket.emit('answerSubmitted', { roomCode, playerName, answer: option, bonusPoints }); // Send bonus points with the answer
        }
    };

    const progressPercentage = (timer / 10) * 100;
    const progressColor = `rgb(${255 - (255 * (timer / 10))}, ${255 * (timer / 10)}, 0)`;

    return (
        <div className="player-container">
            {!gameStarted ? (
                <div className="join-game-container">
                    <h2>Join a Game</h2>
                    <input type="text" placeholder="Room Code" value={roomCode} onChange={(e) => setRoomCode(e.target.value)} className="input-field" />
                    <input type="text" placeholder="Your Name" value={playerName} onChange={(e) => setPlayerName(e.target.value)} className="input-field" />
                    <button onClick={joinGame} className="submit-button">Join</button>
                    {roomCode && (
                        <div className="room-info">
                            <h3>Room Code: {roomCode}</h3>
                            <h4>Participants:</h4>
                            <ul className="no-bullets">
                                {participants.map((participant, index) => (
                                    <li key={index}>{participant}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            ) : showScoreboard ? (
                <div className="results-container">
                    <h3>Results</h3>
                    <h4>You {result ? 'answered correctly!' : 'got it wrong.'}</h4>
                    <h4>Points Earned: {result ? `100 + ${timer * 10} bonus points` : '0'} pts</h4>
                    <h4>Scoreboard:</h4>
                    <ul className="no-bullets">
                        {Object.values(scoreboard).sort((a, b) => b.score - a.score).map((player, index) => (
                            <li key={index}>{player.name}: {player.score} pts</li>
                        ))}
                    </ul>
                </div>
            ) : (
                <div className="question-container">
                    <div className="progress-bar-container">
                        <div className="progress-bar" style={{ width: `${progressPercentage}%`, backgroundColor: progressColor }}></div>
                    </div>
                    <h4>Timer: {timer} seconds left</h4>
                    <h3>Question: {currentQuestion?.question}</h3>
                    <div className="answer-grid">
                        {!answered && currentQuestion?.options.map((option, index) => (
                            <button key={index} onClick={() => submitAnswer(option)} className="answer-button">{option}</button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Player;
